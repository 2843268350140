<script>
    export let pageview;


    import * as d3 from "d3"
    const comma = d3.format(',');

</script>




<!--
{#if pageview}

    <div class="
            no-print
            pt-8
            pb-0
            mb-0
            text-xs
            text-center
            bg-[#427FED]
            text-white
        ">
        Page view:
        <span style="white-space:nowrap;">{ comma( pageview ) }</span>
    </div>
{/if} -->

