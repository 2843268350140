<script>

export let size;
export let item_code;
export let item;


// console.log('size',size);

$: px = (38/200)*size;
$: py = (150/200)*size;
$: pw = (123/200)*size;
$: ph = (35/200)*size;

    import {
        makeURLKey
    } from '$lib/components/Utilities.svelte';

</script>

<!--
<svg width="{size}" height="{size}" viewBox="0 0 200 200">
  <image alt="{item}" href="https://img.manamurah.com/barang_nobg/{item_code}.png" width="200" height="200"/>
  <image alt="PriceCatcher" href="https://img.manamurah.com/logo/pricecatcher.png" x="38" y="150" width="123" height="35"/>
</svg>

-->

<div style="display:flex;justify-content:center;align-items:center;">
  <div style="flex:0 0 {size}px">
    <div style="position:relative; width:{size}px; height:{size}px">
      <div style="display:block; position:absolute;top:0;left:0;width:{size}px;height:{size}px;">
        <img
            alt="{item}"
            src="https://img.manamurah.com/barang_nobg/{item_code}.png"
            style:--tag={'item'+item_code}
            style="display:block; position:absolute;top:0;left:0;width:{size}px;height:{size}px;"/>
      </div>
      <div style="display:block; position:absolute;top:0;left:0;width:{size}px;height:{size}px;">
        <img
            alt="PriceCatcher"
            src="https://img.manamurah.com/logo/pricecatcher.png"
            style="display:block; position:absolute;top:{py}px;left:{px}px; width:{pw}px; height:{ph}px"/>
      </div>
    </div>
  </div>
</div>
