<script>

    export let width;
    export let dbg;


    import * as d3 from 'd3';


    let affiliates = [


        // 2024-04-08

        ['Shopee', 'https://atmy.me/0029wi002cr0', 'https://s3-ap-southeast-1.amazonaws.com/images.accesstrade.co.id/0f840be9b8db4d3fbd5ba2ce59211f55/logo_20220717030415.png'],
        ['Watsons', 'https://atmy.me/go/DBP2zqTd' ,'https://down-tx-my.img.susercontent.com/762c4d976951678921c8c55f5a4e7bb2_tn.webp'],
        ['Edifier', 'https://atmy.me/go/oufwh7fY', 'https://down-tx-my.img.susercontent.com/516028b41303b6c4b5c8cfd0a5033405_tn.webp'],
        ['Goojodoq', 'https://atmy.me/go/w5w1aODo', 'https://down-tx-my.img.susercontent.com/0b7a032376f40f9ea52b2a18b971b062_tn.webp'],
        ['Lotus`s','https://atmy.me/go/mEb9Cdh5','https://s3-ap-southeast-1.amazonaws.com/images.accesstrade.co.id/926ffc0ca56636b9e73c565cf994ea5a/logo_20221020042844.png'],

        // ['CY Babyshop','https://atmy.me/go/yD1GHUQc','https://cf.shopee.com.my/file/my-11134233-7qul2-lkey8cld3pbp54'],
        // ['agbaby.my','https://atmy.me/go/KWWzSv5R','https://cf.shopee.com.my/file/f0c21f065d3ebf0b93e1355e80288a01'],
        // ['Blessed Bee Store','https://atmy.me/go/2rwXBbUV','https://cf.shopee.com.my/file/78c059f62ab819d928f8d9038a69f215'],
        // ['AC Store','https://atmy.me/go/x4HmfI6I','https://cf.shopee.com.my/file/7cfce638597afb4f7419000c2d69b954'],

        // ['dresslily','https://atmy.me/000qup002cr0','https://s3-ap-southeast-1.amazonaws.com/images.accesstrade.co.id/cdc0d6e63aa8e41c89689f54970bb35f/logo_20230517010428.png'],
        // ['One To Plus','https://atmy.me/go/i1x2vh3T','https://cf.shopee.com.my/file/2595165b0e3bfd6ef3796b6fd9287b6b'],
        // ['Meetist','https://atmy.me/go/J1QoRFip','https://cf.shopee.com.my/file/my-11134216-7r98x-lqsoxo9ux6tx03'],

        ['Samsung','https://atmy.me/go/pCKTi9IV','https://cf.shopee.com.my/file/aae980063e0cf71a48c061b9d429f873'],

        ['Braun','https://atmy.me/go/2HBeWZHM','https://cf.shopee.com.my/file/32d1faec5e0b5647bc811ec45946e53b'],
        ['LG','https://atmy.me/go/Gt9Gm7UP','https://cf.shopee.com.my/file/my-11134216-7r98t-ltd6dqbmim903b'],

        ['Levi\`s','https://atmy.me/go/jTTMCvXG','https://cf.shopee.com.my/file/001f43f5771abdc68c9481d96257f8bc'],
        ['Larrie','https://atmy.me/go/fECgnXqk','https://cf.shopee.com.my/file/d0371c8febf265c8dbb12c0f9836cbd5'],
        ['Hush Puppies','https://atmy.me/go/fN5KkaQY','https://cf.shopee.com.my/file/60b34e71f975e18309b49aabbf0ec442'],
        ['Converse','https://atmy.me/go/QAlAOuPi','https://cf.shopee.com.my/file/120701d4ec6a7dc5a5a43f5931b6b87c'],
        ['UnderArmour','https://atmy.me/go/Jesry3bp','https://cf.shopee.com.my/file/38b66b15164b4c7d322e4cca67c794cb'],
        ['Skechers','https://atmy.me/go/cFlDf1K1','https://cf.shopee.com.my/file/845c57ea5c0113cf301d46689f745b81'],


        ['Nespresso','https://atmy.me/go/NNCGRfDh','https://cf.shopee.com.my/file/2fe6b61f9529c07e3b1e4917fa56f629'],
        ['GSK','https://atmy.me/go/OYqq69ar','https://cf.shopee.com.my/file/879b7391083b1b2d81b471811b62fcb1'],
        ['Colgate','https://atmy.me/go/mufPBJ7h','https://cf.shopee.com.my/file/4f1b8430b7aaa69a5522dbb86edceadd'],
        ['LEGO','https://atmy.me/go/Ts9oKX0Z','https://cf.shopee.com.my/file/1d10dd3bc6a306626a6f2fbe6766a4b4'],

        // ['meesiput','https://atmy.me/go/iO9ZAh6m','https://cf.shopee.com.my/file/70c704f0a253fccd9094a718e47f0780'],
        ['Ray-Ban','https://atmy.me/go/rMpnVcb2','https://cf.shopee.com.my/file/my-11134216-7r98y-lsqpo8yqiskk49'],
        ['Khind','https://atmy.me/go/M9G1ymIg','https://cf.shopee.com.my/file/73a3bed18e57b8c10ad5e341ce6d4605'],

        ['GP Batteries','https://atmy.me/go/nmU5Q6uj','https://cf.shopee.com.my/file/bb46100545b469d372800b9f3a7e0046'],
        ['Billabong','https://atmy.me/go/XVCykbGV','https://cf.shopee.com.my/file/my-11134216-7r98v-lntdzqgerhq549'],
        ['Shiseido','https://atmy.me/go/pffMfCO2','https://cf.shopee.com.my/file/e30ef137f1ff6621c87b21b2a4fd8c09'],
        ['Similac','https://atmy.me/go/suVTXiwR','https://cf.shopee.com.my/file/3cd514691a88d48bb9b2f087284f8d15'],
        ['Goojodoq','https://atmy.me/go/p6FMfyZn','https://cf.shopee.com.my/file/cn-11134216-7r98o-loayjz23urdv95'],
        ['Anmum','https://atmy.me/go/X5t919oN','https://cf.shopee.com.my/file/558bb9f71670afad25e0e1d2a0189bf0'],

        ['Huawei','https://atmy.me/go/NSs12H4b','https://cf.shopee.com.my/file/2ffaff255131226ede7069af71ccd9c1'],
        ['BOSE','https://atmy.me/go/3yeoIKDD','https://cf.shopee.com.my/file/1b89c9b723d6459d1c2287cf82ab41e7'],
        ['Tefal','https://atmy.me/go/NGErMD3s','https://cf.shopee.com.my/file/81dd9762d972c4c7efa942b03e922110'],

        ['3M','https://atmy.me/go/3D9KKcPh','https://cf.shopee.com.my/file/6b45d859c49572ea0127c0a5568a55cc'],
        ['Loreal','https://atmy.me/go/WoWb1rbX','https://cf.shopee.com.my/file/e54c0215dbb649a46124cb2093adceba'],
        ['Ajinimoto','https://atmy.me/go/U6XNmwjP','https://cf.shopee.com.my/file/0349e5358f1fe59c387d7d612664a32d'],
        ['Spritzer','https://atmy.me/go/Gl6VnNFT','https://cf.shopee.com.my/file/96b7c70a2b51b00c2c27541ef88c78b0'],

        ['Whiskas','https://atmy.me/go/gdpbpP2c','https://cf.shopee.com.my/file/c1d319aea0c255626a2e274c8eeea669'],
        ['Panasonic','https://atmy.me/go/gR5aWETm','https://cf.shopee.com.my/file/4909c1b880d2dd1aabeb6c2cb1002a1c'],
        ['Samsung','https://atmy.me/go/anl8SR6c','https://cf.shopee.com.my/file/3e87fbc66c4c41e850c673c88b634047'],

        ['Anakku','https://atmy.me/go/4YEKi7oq','https://cf.shopee.com.my/file/7368d1f416b6749926904a9cc60dac68'],
        ['Philips','https://atmy.me/go/CgglIe8B','https://cf.shopee.com.my/file/c09fd4081d94680d27b52a0ed7c72c53'],
        ['Philips','https://atmy.me/go/u8j3YMsa','https://cf.shopee.com.my/file/e9cd04f27481b687b4c982e93eb460c2'],

        ['Royal Canin','https://atmy.me/go/TM0N97Ax','https://cf.shopee.com.my/file/e9bdc9c64504b7c3432973aedec7c712'],
        ['Yoobao','https://atmy.me/go/DRhHx90v','https://cf.shopee.com.my/file/60b167807490dbe635eaed643cecf188'],
        ['Pelangi','https://atmy.me/go/aP3HeV7u','https://cf.shopee.com.my/file/57615c50de022b866658aeea81f8ed04'],

        ['Dunlopillo','https://atmy.me/go/ddHhMwjc','https://cf.shopee.com.my/file/d33ded5adaf5c76886b2b22d16588b87'],
        ['Zalora','https://atmy.me/go/B7FvliTA','https://cf.shopee.com.my/file/3637b20918084c267beab95f844550bc'],
        ['Hexa','https://atmy.me/go/3UTNnDWD','https://cf.shopee.com.my/file/1e69c836581fe62cc5bff8462e54dec4'],
        ['Poney','https://atmy.me/go/VqsLMNVf','https://cf.shopee.com.my/file/2679b02c229aa1baf587fe2b2e78645f'],
        ['GoPro','https://atmy.me/go/139M9gjm','https://cf.shopee.com.my/file/cn-11134216-7r98o-lsi3def97w0taf'],
        ['GoPro','https://atmy.me/go/LmwsmyA6','https://cf.shopee.com.my/file/cn-11134216-7r98o-lsi3def96hgdaf'],
        ['Toshiba','https://atmy.me/go/p6Dgellp','https://cf.shopee.com.my/file/8c120982b343ce02d772eb52b46581e9'],
        ['Faver-Castell','https://atmy.me/go/gMntfWcu','https://cf.shopee.com.my/file/5b75e4eb5a246b013631068b2409389c'],
        ['Castrol','https://atmy.me/go/z4J9yNpU','https://cf.shopee.com.my/file/e3f3e747ef9189eda5c11527702c4b30'],
        ['Valvoline','https://atmy.me/go/wmQCVfsG','https://cf.shopee.com.my/file/47257d56e9f99648e7b19f48ac5c8d34'],
        ['Sports Dircet','https://atmy.me/go/pDfrXU2t','https://cf.shopee.com.my/file/25225c748e9205deabda5f6a6e4af025'],
        ['Dugro','https://atmy.me/go/mBlbayd8','https://cf.shopee.com.my/file/my-11134216-7r98u-lqd5pq1x3u4ec2'],
        ['Sustagen','https://atmy.me/go/8TZ51lrP','https://cf.shopee.com.my/file/0186e7086cf36392073f019e904689af'],
        ['Dettol','https://atmy.me/go/1EOosuDM','https://cf.shopee.com.my/file/96c9a69c0d2871e85a59651f4219b4c2'],
        ['Enfagrow','https://atmy.me/go/yuFHPHWq','https://cf.shopee.com.my/file/sg-11134004-23010-k8mzvzekm4lv3f'],

        ['Maybelline','https://atmy.me/go/BP0TUS0Q','https://cf.shopee.com.my/file/acabe18fa8acc2ba8fa21f23784ab3e8'],
        ['Gintell','https://atmy.me/go/n2hj2ZOr','https://cf.shopee.com.my/file/cfbb6cc550c55beda0f547081d31ea4e'],
        ['Lenovo','https://atmy.me/go/30oz8Ibd','https://cf.shopee.com.my/file/ad34028b2a74682bfbf49fe2830beed3'],
        ['Mahnaz Food','https://atmy.me/go/4nTuQR1o','https://cf.shopee.com.my/file/af96d587e529e2169b9aeac88dcd23a5'],
        ['Quiksilver','https://atmy.me/go/iypC3HH3','https://cf.shopee.com.my/file/45c378333ea8616426ddc9626f683cd4'],
        ['Hitachi','https://atmy.me/go/YjBV1q8B','https://cf.shopee.com.my/file/my-11134216-7qul7-lkix05s9gzxxcb'],
        ['Body Shop','https://atmy.me/go/SzQaqCVW','https://cf.shopee.com.my/file/17fb8c41d8a84c6e7d533f87fc78b4a7'],
        ['Guardian','https://atmy.me/go/1jaW4Bpt','https://cf.shopee.com.my/file/70a2dc856b8efad7bab702db0ac40422'],
        ['Elle','https://atmy.me/go/f6UuokiN','https://cf.shopee.com.my/file/my-11134216-7r98y-lnwlbyngljgcef'],
        ['Fipper','https://atmy.me/go/V9zC8rnR','https://cf.shopee.com.my/file/a7ee352f7116a6b720fc3c54e53cdc9e'],
        ['Nivea','https://atmy.me/go/KxSBFg4a','https://cf.shopee.com.my/file/4970376c1f81880bde8093cffa4e69f0'],
        ['Scholl','https://atmy.me/go/C7OD3oRr','https://cf.shopee.com.my/file/my-11134216-7r98y-lq08lzisn2z983'],
        ['Adidas','https://atmy.me/go/bXww0ZZI','https://cf.shopee.com.my/file/0d5760f8b67b945546758071a60e9c9f'],
        ['Bosch','https://atmy.me/go/kaNkgkw0','https://cf.shopee.com.my/file/ccbb5bf8593e67290348f1b3477a66b9'],
        ['Zaitun','https://atmy.me/go/m3d6Xv4m','https://cf.shopee.com.my/file/my-11134004-7qula-ljmkfa9enyvqfa'],

        // 2024-04-15
        ['H&M', 'https://atmy.me/go/JQ2T1jrb', 'https://cf.shopee.com.my/file/9609755a8c0b4bcc2aa02eea4c6358d9'],
        ['TCL','https://atmy.me/go/uJeCYqLB','https://cf.shopee.com.my/file/sg-11134004-7qvfl-li2tqe09l0xy0c'],
        ['GP Batteries','https://atmy.me/go/nmU5Q6uj','https://cf.shopee.com.my/file/bb46100545b469d372800b9f3a7e0046'],
        ['Samurai Paint','https://atmy.me/go/KZIHVOuR','https://cf.shopee.com.my/file/8befbc74d6ecaf2214084261f99f3153'],
        ['Dr Cardin','https://atmy.me/go/IntdYt32','https://cf.shopee.com.my/file/af3077cb1679e91f7bb2ebf9b55d0282'],
        ['Under Armour','https://atmy.me/go/Jesry3bp','https://cf.shopee.com.my/file/38b66b15164b4c7d322e4cca67c794cb'],
        ['Quiksilver','https://atmy.me/go/iypC3HH3','https://cf.shopee.com.my/file/45c378333ea8616426ddc9626f683cd4'],
        ['Dugro','https://atmy.me/go/mBlbayd8','https://cf.shopee.com.my/file/my-11134216-7r98u-lqd5pq1x3u4ec2'],
        ['Vivo','https://atmy.me/go/e8qbQ4OM','https://cf.shopee.com.my/file/bc8c311a30e64400989c82d3bbdec333'],
        ['Enfagrow','https://atmy.me/go/yuFHPHWq','https://cf.shopee.com.my/file/sg-11134004-23010-k8mzvzekm4lv3f'],
        ['Sustagen','https://atmy.me/go/8TZ51lrP','https://cf.shopee.com.my/file/0186e7086cf36392073f019e904689af'],
        ['LEGO','https://atmy.me/go/Ts9oKX0Z','https://cf.shopee.com.my/file/1d10dd3bc6a306626a6f2fbe6766a4b4'],
        ['D-Link','https://atmy.me/go/B7IfCq9V','https://cf.shopee.com.my/file/098fafea3b189ed6989193f450580a3a'],
        ['Decathlon','https://atmy.me/go/v8KV9BbO','https://cf.shopee.com.my/file/my-11134216-7r990-lsteyhyq7gtzb5'],
        ['Huawei','https://atmy.me/go/NSs12H4b','https://cf.shopee.com.my/file/2ffaff255131226ede7069af71ccd9c1'],
        ['Braun','https://atmy.me/go/2HBeWZHM','https://cf.shopee.com.my/file/32d1faec5e0b5647bc811ec45946e53b'],
        ['Kawasaki','https://atmy.me/go/wfiHi8CV','https://cf.shopee.com.my/file/cn-11134216-7r98o-lmsbxhamn48269'],
        ['Levi`s','https://atmy.me/go/jTTMCvXG','https://cf.shopee.com.my/file/001f43f5771abdc68c9481d96257f8bc'],
        ['Larries','https://atmy.me/go/fECgnXqk','https://cf.shopee.com.my/file/d0371c8febf265c8dbb12c0f9836cbd5'],
        ['Colgate','https://atmy.me/go/mufPBJ7h','https://cf.shopee.com.my/file/4f1b8430b7aaa69a5522dbb86edceadd'],
        ['Dermalogica','https://atmy.me/go/fN4v79mf','https://cf.shopee.com.my/file/6a424485f49037d99fe8cb854b67a5ff'],
        ['Toyo-G','https://atmy.me/go/KiMgUjUQ','https://cf.shopee.com.my/file/8136360e75db3766a86df5079247ffb1'],
        // ['MyJannah Baby Shop','https://atmy.me/go/p6QE8Wca','https://cf.shopee.com.my/file/0c26bbca4551d5821bdf453a4b94626e'],
        ['A1','https://atmy.me/go/0gYqkQBL','https://cf.shopee.com.my/file/dc218f7fe739362ee84a2aaa79462743'],
        // ['Johnson Fitness & Wellness','https://atmy.me/go/VjvCWRYr','https://cf.shopee.com.my/file/d7e158c2d325934b7c200e76c4899975'],
        ['Johnson Fitness','https://atmy.me/go/VjvCWRYr','https://cf.shopee.com.my/file/d7e158c2d325934b7c200e76c4899975'],
        ['Khind','https://atmy.me/go/M9G1ymIg','https://cf.shopee.com.my/file/73a3bed18e57b8c10ad5e341ce6d4605'],
        ['Jamu Mak Dara','https://atmy.me/go/xotQS1xm','https://cf.shopee.com.my/file/my-11134216-7r98o-lkotgd5lgjza7e'],
        ['Billabong','https://atmy.me/go/XVCykbGV','https://cf.shopee.com.my/file/my-11134216-7r98v-lntdzqgerhq549'],
        ['Shiseido','https://atmy.me/go/pffMfCO2','https://cf.shopee.com.my/file/e30ef137f1ff6621c87b21b2a4fd8c09'],
        ['DIZO','https://atmy.me/go/JLLj0hY1','https://cf.shopee.com.my/file/14e3e4503084876eaa7434f30acf2409'],
        ['Camel Active','https://atmy.me/go/77RDZO76','https://cf.shopee.com.my/file/d5aa273d8ded85c0a600735751d71b90'],
        ['Hush Puppies','https://atmy.me/go/fN5KkaQY','https://cf.shopee.com.my/file/60b34e71f975e18309b49aabbf0ec442'],
        ['Speedo','https://atmy.me/go/K2dAgZ04','https://cf.shopee.com.my/file/my-11134216-7r990-lp6d2ew59tiw71'],
        ['Daiichi Sankyo','https://atmy.me/go/uJn8IsY6','https://cf.shopee.com.my/file/8a96e5f53bc54e44806865bd264c53d1'],
        ['Converse','https://atmy.me/go/QAlAOuPi','https://cf.shopee.com.my/file/120701d4ec6a7dc5a5a43f5931b6b87c'],
        ['ALDO','https://atmy.me/go/BavLVL4v','https://cf.shopee.com.my/file/51e730bd699704a273052c18e274ab14'],
        ['PediaSure','https://atmy.me/go/2S6XLEAk','https://cf.shopee.com.my/file/my-11134216-7r98p-lokyibg30y9ab8'],
        ['Anmum','https://atmy.me/go/X5t919oN','https://cf.shopee.com.my/file/558bb9f71670afad25e0e1d2a0189bf0'],
        ['Similac','https://atmy.me/go/cHrIcipG','https://cf.shopee.com.my/file/my-11134216-7r98p-ln14sx4rzoama1'],
        ['Similac','https://atmy.me/go/suVTXiwR','https://cf.shopee.com.my/file/3cd514691a88d48bb9b2f087284f8d15'],
        ['BOSE','https://atmy.me/go/3yeoIKDD','https://cf.shopee.com.my/file/1b89c9b723d6459d1c2287cf82ab41e7'],
        ['Tefal','https://atmy.me/go/NGErMD3s','https://cf.shopee.com.my/file/81dd9762d972c4c7efa942b03e922110'],
        ['Skechers','https://atmy.me/go/cFlDf1K1','https://cf.shopee.com.my/file/845c57ea5c0113cf301d46689f745b81'],
        ['3M','https://atmy.me/go/3D9KKcPh','https://cf.shopee.com.my/file/6b45d859c49572ea0127c0a5568a55cc'],
        ['L`Oreal','https://atmy.me/go/WoWb1rbX','https://cf.shopee.com.my/file/e54c0215dbb649a46124cb2093adceba'],
        ['Ajinomoto','https://atmy.me/go/U6XNmwjP','https://cf.shopee.com.my/file/0349e5358f1fe59c387d7d612664a32d'],
        ['Hijab World','https://atmy.me/go/8W55EoBU','https://cf.shopee.com.my/file/8cf75f7b5c5365175783c4e0258a3b5d'],
        ['Spritzer','https://atmy.me/go/Gl6VnNFT','https://cf.shopee.com.my/file/96b7c70a2b51b00c2c27541ef88c78b0'],
        ['Whiskas','https://atmy.me/go/gdpbpP2c','https://cf.shopee.com.my/file/c1d319aea0c255626a2e274c8eeea669'],
        ['Panasonic','https://atmy.me/go/gR5aWETm','https://cf.shopee.com.my/file/4909c1b880d2dd1aabeb6c2cb1002a1c'],
        ['Hair Atelier','https://atmy.me/go/LjynQjoY','https://cf.shopee.com.my/file/841f72f382156dcc945fba20b6f70aaa'],
        ['Anakku','https://atmy.me/go/4YEKi7oq','https://cf.shopee.com.my/file/7368d1f416b6749926904a9cc60dac68'],
        ['Marshall','https://atmy.me/go/pR2CJ89X','https://cf.shopee.com.my/file/39a19f089c88ebd02ee42361e3081571'],
        // ['Polo Shoes','https://atmy.me/go/a7w9eaZd','https://cf.shopee.com.my/file/8bee017f51afcbbc194d12ed30411070'],
        // ['CampBoss','https://atmy.me/go/9b8ZNRw1','https://cf.shopee.com.my/file/19e753e7ecab28848a89f55e3bbb3515'],
        // ['Packing Box','https://atmy.me/go/Yz13YCm0','https://cf.shopee.com.my/file/1f2a86d732a0fc16c8ce75afbbdd751a'],
        // ['Sally`s Kitchen','https://atmy.me/go/KrvI0zMU','https://cf.shopee.com.my/file/my-11134004-7r990-lqvxt9ytiihw6b'],
        ['Crayola','https://atmy.me/go/9cOc3DA1','https://cf.shopee.com.my/file/065568cd03ca09196051180b1e60c7f0'],
        ['Tanamera','https://atmy.me/go/7LhT5l6A','https://cf.shopee.com.my/file/de164b9972e620a6597339d74262b025'],
        ['Philips','https://atmy.me/go/u8j3YMsa','https://cf.shopee.com.my/file/e9cd04f27481b687b4c982e93eb460c2'],
        ['Philips','https://atmy.me/go/DroEmWRZ','https://cf.shopee.com.my/file/4cf471123625bd593f1ff95befcadb03'],
        ['ProBalance','https://atmy.me/go/zbsxTOhc','https://cf.shopee.com.my/file/918070265546ce56923c67764acb915a'],
        ['Dino Baby','https://atmy.me/go/qkWG0uiU','https://cf.shopee.com.my/file/9fa1de0abb3b5295b7f32b2d5e54f218'],
        ['Pandora','https://atmy.me/go/hJ28POgI','https://cf.shopee.com.my/file/481228b4d0f48b3bf5c1372cdd3936f5'],
        ['Pelangi','https://atmy.me/go/aP3HeV7u','https://cf.shopee.com.my/file/57615c50de022b866658aeea81f8ed04'],
        ['ProLink','https://atmy.me/go/eu8i8o0G','https://cf.shopee.com.my/file/257fc47d7a4d6c3aa65061efe3c0cb43'],
        ['D`Herbs','https://atmy.me/go/dSPzxPjR','https://cf.shopee.com.my/file/my-11134216-7r992-lolzlen8fm8ma5'],
        // ['Mr Dedak','https://atmy.me/go/KO8vlnGt','https://cf.shopee.com.my/file/a2ade60bd8b22ad461b9bdf4375ed77e'],
        ['UHU','https://atmy.me/go/OKe8t2gO','https://cf.shopee.com.my/file/afd575ff0ec373f6bcb0b3d805f60ea2'],
        ['Philips','https://atmy.me/go/tsPijDH1','https://cf.shopee.com.my/file/5d43991e40a42253d98b5f1806511b3e'],
        ['Medicube','https://atmy.me/go/8Kuq3atM','https://cf.shopee.com.my/file/my-11134216-7r98o-lrsigjnzv0frc3'],
        ['ADATA','https://atmy.me/go/pfdLm3bg','https://cf.shopee.com.my/file/677a166a597ac9f4416442101ef597e8'],
        ['Hexa','https://atmy.me/go/3UTNnDWD','https://cf.shopee.com.my/file/1e69c836581fe62cc5bff8462e54dec4'],
        ['Ray-Ban','https://atmy.me/go/rMpnVcb2','https://cf.shopee.com.my/file/my-11134216-7r98y-lsqpo8yqiskk49'],
        ['Oakley','https://atmy.me/go/ni2Jbahm','https://cf.shopee.com.my/file/3a8d014fea11128333a36628af277925'],
        ['Hansaplast','https://atmy.me/go/4NQsUk7T','https://cf.shopee.com.my/file/e1b928c49c8f7a4878d66c1cd0a3a9e5'],


        // accesstrade
        // 2024-04-24
        ['Lotus`s','https://atmy.me/008s1j002cr0','https://s3-ap-southeast-1.amazonaws.com/images.accesstrade.co.id/926ffc0ca56636b9e73c565cf994ea5a/logo_20221020042844.png'],
        ['Lotus`s','https://atmy.me/go/mEb9Cdh5','https://s3-ap-southeast-1.amazonaws.com/images.accesstrade.co.id/926ffc0ca56636b9e73c565cf994ea5a/logo_20221020042844.png'],

        // 2024-06-05
        ['Dutch Lady','https://atmy.me/go/TAmerMtU','https://cf.shopee.com.my/file/f9ea0a2721ea0f457b4358d7070b3b4e'],
        ['Factory Mart','https://atmy.me/go/IljsCWLP','https://cf.shopee.com.my/file/22d0efc53f14835a9baf8d5ec3e6363d'],
        ['Nespresso','https://atmy.me/go/NNCGRfDh','https://cf.shopee.com.my/file/2fe6b61f9529c07e3b1e4917fa56f629'],
        ['3M','https://atmy.me/go/3D9KKcPh','https://cf.shopee.com.my/file/6b45d859c49572ea0127c0a5568a55cc'],
        ['Tefal','https://atmy.me/go/NGErMD3s','https://cf.shopee.com.my/file/81dd9762d972c4c7efa942b03e922110'],
        ['Speedo','https://atmy.me/go/K2dAgZ04','https://cf.shopee.com.my/file/my-11134216-7r990-lp6d2ew59tiw71'],
        ['Casio','https://atmy.me/go/rF5dQey9','https://cf.shopee.com.my/file/9840bbafa0f64aad6492df77fa468f2b'],
        ['Skechers','https://atmy.me/go/cFlDf1K1','https://cf.shopee.com.my/file/845c57ea5c0113cf301d46689f745b81'],

        // 2024-06-07

        ['Lazada','https://atmy.me/000lmx002cr0','https://s3-ap-southeast-1.amazonaws.com/images.accesstrade.co.id/8d34201a5b85900908db6cae92723617/logo_20210121045200.png'],


        // commission
        // ['Alam Nur Beauty','https://atmy.me/go/szArnh2n','https://cf.shopee.com.my/file/26a20c6ecc9dd278729be6515a35c9e6'],
        // ['Hautuki','https://atmy.me/go/4tUQYg5m','https://cf.shopee.com.my/file/sg-11134216-7rbli-lm316ektnad51d'],
        // ['My Lov3','https://atmy.me/go/CcAfZJ5a','https://cf.shopee.com.my/file/my-11134216-7r98s-lm6yo237kgie94'],
        // ['L Selection','https://atmy.me/go/VBMjJdR0','https://cf.shopee.com.my/file/my-11134004-7r98u-lqcsd9yru40ld6'],
        // ['Majestic Mall','https://atmy.me/go/aP5jJP8u','https://cf.shopee.com.my/file/my-11134216-7r991-lnjglo2c90u5a5'],
        // ['goodVshare','https://atmy.me/go/otSqvvaG','https://cf.shopee.com.my/file/my-11134216-7r98o-llj2ip2rm1d968'],
        // ['Lilien','https://atmy.me/go/5do0NEvc','https://cf.shopee.com.my/file/my-11134216-7r98x-lsur3w0ych0k6f'],
        // ['Belif','https://atmy.me/go/FuQgdLxm','https://cf.shopee.com.my/file/3e915241ba5458a531f0fef35c134147'],
        // ['Infinity Health','https://atmy.me/go/2c2XSVbc','https://cf.shopee.com.my/file/be2668dadb3fc3903e0173dfc7f0e135'],
        // ['WellLand','https://atmy.me/go/MWaM8KK3','https://cf.shopee.com.my/file/my-11134216-7r98o-lkp0cl8kl1qjbc'],
        // ['PNI Neuron','https://atmy.me/go/5G9dNA9U','https://cf.shopee.com.my/file/fd2efe56fd36c6b7c73a8e2c22c6b4c1'],
        // ['Rosjan','https://atmy.me/go/vSwhCcS5','https://cf.shopee.com.my/file/ba7c47e54b36acba21429b23e4d31d90'],
        // ['Art&Fact','https://atmy.me/go/CXqnFF3k','https://cf.shopee.com.my/file/820f7dfe0e368b2726c17515f33c8406'],
        // ['SmoothSkin','https://atmy.me/go/wiMVO6wm','https://cf.shopee.com.my/file/7ce488305810711eac77324d3fba6b86'],
        // ['Lift Onyx','https://atmy.me/go/9T9SjzYe','https://cf.shopee.com.my/file/my-11134233-7r98o-lkqz4h93lhknc7'],
        // ['Clovee','https://atmy.me/go/VqF5bMKh','https://cf.shopee.com.my/file/6a55e7ac6498e622e4fadefb9969cfc1'],
        // ['Jaesul','https://atmy.me/go/TI4lolZf','https://cf.shopee.com.my/file/56d4cdc709a8ffe9575af02d64e662c1'],
        // ['Hairband','https://atmy.me/go/Qu6aDZR5','https://cf.shopee.com.my/file/77682350d5f5e8d89e30ec527436f01f'],
        // ['Mask Family','https://atmy.me/go/vwT9TQaz','https://cf.shopee.com.my/file/my-11134216-7r98r-llugnbil16dy05'],
        // ['Bathkitchz','https://atmy.me/go/FyEUmWSJ','https://cf.shopee.com.my/file/560fedbac4dc47d9ac064fe55ee63b54'],
        // ['Wawawax','https://atmy.me/go/7nLqDnK5','https://cf.shopee.com.my/file/d3b3bec04b1d6efeddc0182ad2c6644c'],
        // ['Evolcare','https://atmy.me/go/wSc3AU5p','https://cf.shopee.com.my/file/524906a6a2f3d3f82f645a7682d220b7'],
        // ['Puplemusic','https://atmy.me/go/t9B95vwA','https://cf.shopee.com.my/file/sg-11134004-7qvcu-lhx55wrcgqum2a'],
        // ['Dear Doer','https://atmy.me/go/59xMPTTS','https://cf.shopee.com.my/file/b539a06f4edd7bdcf82c2fa0250dff09'],
        // ['Little Sana','https://atmy.me/go/Xqgvbme1','https://cf.shopee.com.my/file/c9309ab576351b90c3e0c0e8e707e6e6'],
        // ['Demica','https://atmy.me/go/ik8q7QhM','https://cf.shopee.com.my/file/0ed990e0c232c9f34e980499cf661a4e'],
        // ['Itsuki','https://atmy.me/go/0V9mXVKE','https://cf.shopee.com.my/file/my-11134216-7r991-lmvxbe1kbh5iec'],
        // ['Coolkin','https://atmy.me/go/zTRuP8bR','https://cf.shopee.com.my/file/cn-11134216-7r98o-lohn7w4qhciq3b'],
        // ['Beeul','https://atmy.me/go/inoiNDEZ','https://cf.shopee.com.my/file/0b38621197102d04bff198b60c0cf71f'],
        // ['SkinFood','https://atmy.me/go/kT7ZnuJa','https://cf.shopee.com.my/file/a343795b5cf9e6cf3e364f36eb594204'],
        // ['Apiyoo','https://atmy.me/go/afwK4Xsz','https://cf.shopee.com.my/file/my-11134216-7r98p-lokpi9wo7iomf3'],
        // ['Zemvelo','https://atmy.me/go/uMxN4ude','https://cf.shopee.com.my/file/my-11134004-7r98s-lokx4llc9e9ac5'],
        // ['Tessan','https://atmy.me/go/5MmAesHS','https://cf.shopee.com.my/file/cn-11134216-7r98o-lm04ykdandus78'],
        // ['HiBlendr','https://atmy.me/go/KCJ0N3Ql','https://cf.shopee.com.my/file/my-11134216-7r98w-lsb3df023lrrd0'],
        // ['papa recipe','https://atmy.me/go/DIjlkLGt','https://cf.shopee.com.my/file/6488cd57b672967b1f40a8c99b289e08'],
        // ['INGA','https://atmy.me/go/V2U7tIiq','https://cf.shopee.com.my/file/ce3ac5a129ab64cf00a6facb2aeacbd0'],
        // ['BYM','https://atmy.me/go/Z2iv5r6y','https://cf.shopee.com.my/file/cn-11134216-7r98o-lsl3a1b24ezh87'],
        // ['XYZ Marketing','https://atmy.me/go/rUKVdNWN','https://cf.shopee.com.my/file/e55ac02f0f6809c577fb3261115ce24e'],
        // ['TinyCrew','https://atmy.me/go/UEGDx6bt','https://cf.shopee.com.my/file/my-11134216-7r990-llxawj98l40m7b'],



        // involve
        ['FlowerAdvisor','https://invol.co/clkzu8g','https://img.involve.asia/ia_logo/ia_tmb/2258_zjqTWX3L.png','involve'],
        // ['Shopee MY','https://invl.io/clkzu8r','https://img.involve.asia/ia_logo/ia_tmb/4223_YL6yAQiT.png','involve'],
        ['Poney','https://invol.co/clkzu92','https://img.involve.asia/ia_logo/ia_tmb/4148_3hUZwFit.jpg','involve'],
        ['Poney','https://invol.co/clkzua5','https://img.involve.asia/ia_logo/ia_tmb/4148_3hUZwFit.jpg','involve'],
        ['Pets Wonderland','https://invol.co/clkzu96','https://img.involve.asia/ia_logo/ia_tmb/2205_clzodIUy.jpg','involve'],
        ['Eat Cake Today','https://invol.co/clkzu9d','https://img.involve.asia/ia_logo/ia_tmb/4094_RctP9X5v.png','involve'],
        ['Sephora','https://invol.co/clkzu9i','https://img.involve.asia/ia_logo/ia_tmb/2161_1QLK46DG.png','involve'],
        ['Adidas','https://invol.co/clkzuap','https://img.involve.asia/ia_logo/ia_tmb/2007_Ja8hhgbG.png','involve'],
        ['Big Pharmacy','https://invol.co/clkzypx','https://img.involve.asia/ia_logo/ia_tmb/4012_XgIWvhre.jpg','involve'],
        // ['Senheng','https://invol.co/clkzyqb','https://img.involve.asia/ia_logo/ia_tmb/4267_5SFQTVt3.jpeg','involve'],
        // ['Senheng','https://invol.co/cll1aao','https://img.involve.asia/ia_logo/ia_tmb/4267_5SFQTVt3.jpeg','involve'],
        ['AmazingGrace','https://invle.co/cll0tsw','https://img.involve.asia/ia_logo/ia_tmb/3869_nOV1XiRR.png','involve'],
        // ['Swarovski','https://invol.co/cll0xe7','https://img.involve.asia/ia_logo/ia_tmb/3523_mhXlvpDU.jpg','involve'],
        ['Ringgit Plus','https://invol.co/cll19n5','https://img.involve.asia/ia_logo/ia_tmb/4088_zdStAad5.png','involve'],

        ['MacBook Air','https://invol.co/cll1asn','https://img.involve.asia/rpss/campaigns_banners/78104-0GMAS7UalPlSY77WpHOBDj3Uv92DjDx2.jpg','involve'],
        ['Maybank Zest-i','https://invle.co/cll2i23','https://img.involve.asia/ia_logo/ia_tmb/3976_f3WHZjyS.jpg','involve'],

        ['Flower Chimp','https://invle.co/cll35r2','https://img.involve.asia/ia_logo/ia_tmb/4109_tzySAEkM.png','involve'],
        ['Charles & Keith','https://invle.co/cll35re','https://img.involve.asia/ia_logo/ia_tmb/4023_ntOcb1xS.jpg','involve'],

        ['Adidas Sale','https://invol.co/cll35yg','https://img.involve.asia/rpss/campaigns_banners/37723-ydDjciWHxFvEKP6CFHAmcvG6wQK0dkbO.jpeg','involve'],

        ['TikTok Shop','https://invle.co/cll51a5','https://img.involve.asia/ia_logo/ia_tmb/4388_aCToURIc.png','involve'],












































    ].map(d=>{
        return {
            label: d[0],
            url: d[1],
            imgurl: d[2],
            affiliate: d[3] || 'accesstrade',
        }
    });







    // $: accesstrade = d3.shuffle(
    //         d3.groups(affiliates, d=>d.label).map(d=>d3.shuffle(d[1])[0])
    //     ).slice(0, 3 * d3.max([3, Math.floor(width/110) ]));


    let list = [];

    import { onMount } from 'svelte';
    onMount(()=>{

        list = d3.shuffle(
            d3.groups(affiliates, d=>d.label).map(d=>d3.shuffle(d[1])[0])
        );

    });

    $: accesstrade = list.slice(0, 3 * d3.max([3, Math.floor(width/110) ]));

</script>



{#if accesstrade.length}
    <div class="p-1 mb-10">

            <div class="flex justify-center items-center mb-4">
                <div class="flex-1 mr-4">
                    <div class="w-full opacity-50" style="border:1px solid #ccc"></div>
                </div>
                <div class="flex opacity-50 text-xs">Online Stores</div>
                <div class="flex-1 ml-4">
                    <div class="w-full opacity-50" style="border:1px solid #ccc"></div>
                </div>
            </div>

        <div class="p-1 flex justify-center items-end flex-wrap gap-2">
            {#each accesstrade as d}
                <div class="flex-1">
                    <a href="{d.url}" target="_blank" class="flex justify-center">
                        <img src="{d.imgurl}" style="max-width:100px;max-height:100px" class="self-center text-center"/>
                    </a>
                    <div>
                        <a href="{d.url}" target="_blank" class="flex justify-center text-xs truncate w-[100px]">{d.label}</a>
                    </div>
                </div>
            {/each}
        </div>
    </div>
{/if}