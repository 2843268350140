<script>

    export let _affiliates;
    export let width;
    export let dbg;


    import * as d3 from 'd3';
    const f2 = d3.format(",.2f");

    import * as Card from "$lib/components/ui/card"
    // import * as Carousel from "$lib/components/ui/carousel/index.js";

    import { qr } from '@svelte-put/qr/svg';

    dbg = false;



    // $: affiliates = d3.shuffle(_affiliates||[])
    //                     .slice(0, Math.floor((width||1024)/120)*3)




    let list = [];

    import { onMount } from 'svelte';
    onMount(()=>{

        list = d3.shuffle(
            d3.groups(_affiliates||[])
        );

    });

    $: affiliates = list.slice(0, 3 * d3.max([3, Math.floor((width||1024)/120) ]));

    // $: console.log('width', width, affiliates.length);


    import ImageZoom from './ImageZoom.svelte';


</script>


{#if affiliates && affiliates.length}
    <div class="affiliates_products my-10">
        <div class="flex justify-center items-center">
            <div class="flex-1 mr-4">
                <div class="w-full opacity-50" style="border:1px solid #ccc"></div>
            </div>
            <div class="flex opacity-50 text-xs">Buy Online</div>
            <div class="flex-1 ml-4">
                <div class="w-full opacity-50" style="border:1px solid #ccc"></div>
            </div>
        </div>

        <div class="flex justify-center items-stretch flex-wrap gap-0 mt-4 overflow-hidden">
            {#each affiliates as d, i}
                    <a href={d.link} target="_blank" class="flex-1 p-0 min-w-[120px] max-w-[140px] relative" style="max-width:120px;">
                        <Card.Root class="h-[250px] relative  overflow-hidden m-0 pt-4 flex justify-center rounded-none w-full">
                            <Card.Content class="-mt-4 p-0 overflow-hidden w-[120px] h-[120px]">
                                <ImageZoom src="{d.image_link}" alt="{d.title}"/>
                            </Card.Content>

                            <Card.Content class="absolute top-[120px] ml-0 p-1 flex justify-center w-[120px]" style="max-width:120px;">
                                <div class="flex-1 self-center">

                                    <div class="text-xl text-[#eb4428] font-semibold">RM{f2(d.price)}</div>
                                    <div class="text-xs leading-4 mt-[-4px] ml-1">
                                        {#if d.affiliate && !d.affiliate.match(/medium/i)}
                                            {#if d.affiliate.match(/lazada/i)}
                                                <span class="bg-[#fd06ff] text-white text-xs px-2 py-1" style="font-size:80%">{d.affiliate.toUpperCase()}</span>
                                            {:else if d.affiliate.match(/senheng/i)}
                                                <span class="bg-[#e8001c] text-white text-xs px-2 py-1" style="font-size:80%">{d.affiliate.toUpperCase()}</span>
                                            {:else}
                                                <span class="bg-[#eb4428] text-white text-xs px-2 py-1" style="font-size:80%">{d.affiliate.toUpperCase()}</span>
                                            {/if}
                                        {/if}
                                        <div>
                                            {@html d.title}
                                        </div>
                                    </div>


                                </div>

                            </Card.Content>

                                <!--
                                    https://svelte-put.vnphanquang.com/docs/qr
                                -->

                                <!-- <svg
                                    class="absolute top-[145px] right-0 w-10 h-10"
                                    use:qr={{
                                        data: d.link,
                                        // logo: 'https://svelte-put.vnphanquang.com/images/svelte-put-logo.svg',
                                        logo: 'https://manamurah.com/mm.svg',
                                        // shape: 'circle',
                                        width: 20,
                                        height: 20,
                                        anchorInnerFill: 'gray',
                                        anchorOuterFill: 'gray',
                                        moduleFill: 'gray',
                                    }}
                                /> -->

                        </Card.Root>
                    </a>
            {/each}
        </div>

    </div>
{/if}
<!--
{#if affiliates && affiliates.length}
    <div class="px-16 py-4 no-print my-8">

        <div class="flex justify-center items-center">
            <div class="flex-1 mr-4">
                <div class="w-full opacity-50" style="border:1px solid #ccc"></div>
            </div>
            <div class="flex opacity-50 text-xs">Buy Online</div>
            <div class="flex-1 ml-4">
                <div class="w-full opacity-50" style="border:1px solid #ccc"></div>
            </div>
        </div>

        <div class="text-center">
            <Carousel.Root
                orientation="{ width <= 400 ? 'vertical' : 'horizontal'}"
                opts={{
                    align:'start',
                    loop:true,
                }}
                class="mt-4"
            >
                <Carousel.Content class=" mt-[-16px]">

                    {#each affiliates as d, i}

                        <Carousel.Item class="basis-1/{ Math.floor(width/175) } pl-1 group self-center">
                            <a href={d.link} target="_blank" class="p-0">
                                <Card.Root class="w-[175px] h-[280px]  relative  overflow-hidden rounded-t-none m-0 pt-4">
                                    <Card.Content class="text-left m-0 p-0 overflow-hidden w-[175px] h-[175px] flex justify-center items-center">

                                        <ImageZoom src="{d.image_link}" alt="{d.title}"/>

                                    </Card.Content>
                                    <Card.Content class="text-left m-0 absolute top-[190px] left-0 ml-[-18px]">

                                        <div class="text-xl text-[#eb4428] font-semibold">RM{f2(d.price)}</div>
                                        <div class="text-xs leading-4 mt-[-4px]">
                                            {#if d.affiliate && !d.affiliate.match(/medium/i)}
                                                {#if d.affiliate.match(/lazada/i)}
                                                    <span class="bg-[#fd06ff] text-white text-xs px-2 py-1" style="font-size:80%">{d.affiliate.toUpperCase()}</span>
                                                {:else}
                                                    <span class="bg-[#eb4428] text-white text-xs px-2 py-1" style="font-size:80%">{d.affiliate.toUpperCase()}</span>
                                                {/if}
                                            {/if}
                                            {@html d.title}
                                        </div>

                                    </Card.Content>
                                </Card.Root>
                            </a>

                        </Carousel.Item>
                    {/each}

                </Carousel.Content>

                <div class="z-10">

                <Carousel.Previous />
                <Carousel.Next />

                </div>

            </Carousel.Root>
        </div>



    </div>
{/if}
 -->
