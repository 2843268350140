<script>
  export let src;
  export let alt = "";
  let scale = 1;
  let originX = 50;
  let originY = 50;

  function handleMouseMove(event) {
    const img = event.currentTarget; // Use currentTarget to ensure we're getting the img element
    const { left, top, width, height } = img.getBoundingClientRect();
    originX = ((event.clientX - left) / width) * 100;
    originY = ((event.clientY - top) / height) * 100;
    scale = 2.5; // Adjust scale factor as needed
  }


    let timer;

    function handleMouseLeave() {

        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            scale = 1;
            originX = 50;
            originY = 50;
        }, 500);

        // scale = 1;
        // originX = 50;
        // originY = 50;
    }

</script>

<style>
  img {
    transition: transform 0.5s ease;
    transform-origin: var(--origin-x) var(--origin-y);
  }
</style>

<img src={src} alt={alt}
  class="zoomable-image"
  style="--origin-x: {originX}%; --origin-y: {originY}%; transform: scale({scale});"
  on:mousemove={handleMouseMove}
  on:mouseleave={handleMouseLeave} />
